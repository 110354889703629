@import '../../variables.modules';

i {
  padding-left: 0.5rem;

  &.red {
    color: $red;
  }

  &.amber {
    color: $yellow;
  }

  &.green {
    color: $green;
  }
}

.terminal {
  p,
  li {
    margin: 1rem 0;
    color: $white;
  }

  a,
  a:visited {
    animation: changeColors 3s infinite;
    font-weight: bold;
  }
}
